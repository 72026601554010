import service from "@/views/user/api";
import api from "@/views/order/firstCheck/api"

/**
 * 用户管理子菜单备注混入
 */

export default{
  data(){
    return {
      showDialog:false,
      // memo:'',
      userId:'',
      flId:null
    }
  },
  methods:{
    //打开备注框
    handleRemarks(row){
      // this.memo = row.memo;
      this.userId = row.userId;
      // this.flId= row.flId;
      this.flId= row.userId;
      this.showDialog = true;
    },
    //确认
    editConfirm(remark){
      // service.remark({userId:this.userId, memo:remark}).then(res => {
      //   this.showDialog = false;
      //   this.$notify({ type: 'success', message: '操作成功！' });
      //   this.init();
      // })
    },
    //取消
    editCancel(){
      this.showDialog = false;
      // this.memo = "";
      this.userId = "";
      this.flId = "";
    },

    ////以下为订单管理的备注编辑
    handleLabel(row){
      this.memo = row.label || "";
      this.userId = row.id;
      this.showDialog = true;
    },
    labelConfirm(label){
      let self = this;
      api.setLabel({orderId: self.userId, label:label}).then(res => {
        self.showDialog = false;
        self.$notify({ type: 'success', message: '操作成功！' });
        // self.$emit('refresh')
      })
    }
  }
}
